/** @format */

import React from "react";
import Moment from "react-moment";
import Markdown from "react-markdown";
import { graphql } from "gatsby";
import { Layout, Section } from "components";
import { GatsbyImage } from "gatsby-plugin-image";
import StickyBox from "react-sticky-box";

export const query = graphql`
  query EventQuery($slug: String!) {
    strapiEvents(slug: { eq: $slug }) {
      title
      strapiId
      slug
      status
      description
      date
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              aspectRatio: 0.70
              transformOptions: { fit: FILL }
              height: 600
            )
          }
        }
      }
      files {
        localFile {
          publicURL
        }
      }
    }
  }
`;

const Event = ({ data }) => {
  const event = data.strapiEvents;
  const seo = {
    metaTitle: event.title,
    metaDescription: event.description,
    shareImage: event.image,
    event: true,
  };

  const formatDate = <Moment format="MM-DD-YYYY">{event.date}</Moment>;

  const files = event.files;

  const File = files.map((download, key) => {
    const url = download.localFile.publicURL;

    return (
      <div className="uk-flex uk-flex-right">
        <a href={url} download={event.title}>
          <button className="uk-button uk-button-default">
            참가 서류 {key + 1} 다운로드
          </button>
        </a>
      </div>
    );
  });

  const images = (
    <div className="uk-flex uk-flex-center uk-flex-middle uk-margin-large-bottom">
      <GatsbyImage
        image={event.image.localFile.childImageSharp.gatsbyImageData}
        className="uk-box-shadow-large"
        alt=""
      />
    </div>
  );
  const texts = (
    <div className="uk-flex uk-flex-middle">
      <div className="uk-flex uk-flex-column">
        <div className="uk-h2 uk-text-bold">{event.title}</div>
        <div className="uk-h4 uk-margin-small">{formatDate}</div>
        <div className="uk-h5">
          <Markdown source={event.description} escapeHtml={false} />
        </div>
        <div>{File}</div>
      </div>
    </div>
  );

  return (
    <Layout seo={seo}>
      <div className="uk-padding-large"></div>
      <Section title="이벤트 정보">
        <div className="uk-flex uk-visible@m">
          <div className="uk-width-1-2">{images}</div>
          <div className="uk-width-1-2 uk-margin-medium-left">{texts}</div>
        </div>
        <div className="uk-flex uk-flex-column uk-hidden@m">
          <div className="uk-flex uk-flex-center uk-flex-middle">{images}</div>
          <div>{texts}</div>
        </div>
      </Section>
    </Layout>
  );
};

export default Event;
